<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>Бюджет</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span>
            <forms-download-reprt
                :progress="progress"
                :isReportUploading="isReportUploading"
                :form="form"
                :guListLen="guListLen"
                @checkSignatories="checkSignatories"
                @downloadRep="downloadRep"
                @downloadBatchReports="downloadBatchReports"
            ></forms-download-reprt>
        </div>
        <div class="filter-container">
            <div class="left-content">
                <budget-header ref="budgetHeader" :form="{spf: form.spf, code: form.code}"
                               @chgData="changeHeader" :yearReq="true" :openDisabled="openDisabled" @setProgress="setProgress"></budget-header>
            </div>
                <div class="right-content">
                <div class="filter-actions filter-actions-flex">
                    <c-budget-forms-copy-data 
                        v-if="!(progress < 100) && variantAttribute && (header.year < header.cur_year + 2)"
                        :budgetForm="budgetForm"
                        :header="header"
                        @keyPress="keyPress"
                    />
                    <b-button variant="success" @click="prepareForSave" :disabled="isLoad || !variantAttribute || load">Сохранить</b-button>
                </div>
            </div>
        </div>

        <breadcrumbs-filter :header="{...header, formName: this.formName, spfName: this.spfName}" :data-type-filter="dataTypeFilter"
                            :variant-name="variantName" @openFilterByRef="openFilterByRef"/>
        <b-progress
                variant="success"
                v-show="progress < 100 && progress > 0"
                height="10px"
                :value="progress"
                striped
                animated
        ></b-progress>

        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                responsive="true"
                bordered
                head-variant="light"
                sticky-header="true"
                no-border-collapse
            >
                <template #top-row="data">
                    <td class="td-numbering"></td>
                    <td class="td-numbering table-success">1</td>
                    <td class="td-numbering table-danger">2</td>
                    <td class="td-numbering table-info">3</td>
                    <td class="td-numbering table-primary">4</td>
                    <td class="td-numbering"></td>
                </template>
                <template #cell(files)="data">
                    <span
                        class="blue pointer underline"
                        @click="openModalRowFilesByRowId(data.item.id, false)"
                    >({{data.item.files}})</span>
                </template>
                <template #head(more)="data">
                    <div></div>
                </template>
                <template #head(action)="scope">
                    <b-button @click="openAll()">
                        <i class="icon icon-keyboard icon-rotate-270" v-if="open"></i>
                        <i class="icon icon-keyboard icon-rotate-90" v-if="!open"></i>
                    </b-button>
                </template>

                <template #cell(action)="data">
                    <b-button @click="showDetails(data.item)">
                        <i class="icon icon-keyboard icon-rotate-270" v-if="data.item._showDetails"></i>
                        <i class="icon icon-keyboard icon-rotate-90" v-if="!data.item._showDetails"></i>
                    </b-button>
                </template>
                <template #cell(number)="data">
                    <div>{{data.item.number}}</div>
                </template>
                <template #cell(name_ru)="data">
                    <div class="scroll-container">{{data.item.name_ru}}</div>
                </template>
<!--                <template #cell(name_kk)="data">-->
<!--                    <div class="scroll-container">{{data.item.name_kk}}</div>-->
<!--                </template>-->
                <template #cell(total)="data">
                    <div class="text-right">{{ $n(data.item.total) }}</div>
                </template>
                <template #cell(more)="data">
                    <b-dropdown v-if="variantAttribute" id="dropdown-dropleft" dropleft class="more">
                        <template v-slot:button-content>
                            <i class="icon icon-more"></i>
                        </template>
                        <template>
                            <b-dropdown-item @click="openModalRowFilesByRowId(data.item.id, true)">
                                Добавить файл(ы)
                            </b-dropdown-item>
                            <b-dropdown-item v-if="variantAttribute" @click="deleteItem(data.item, data.index)">
                                Удалить
                            </b-dropdown-item>
                        </template>
                    </b-dropdown>
                </template>
                <template #bottom-row="data">
                    <td class="text-right" colspan="3">Итого</td>
                    <td class="text-right">{{ $n(total) }}</td>
                    <td colspan="2"></td>
                </template>
                <template #row-details="row">
                    <b-card>
                        <b-table
                            :fields="row.item.rowFields"
                            :items="row.item.regionsList"
                            responsive="true"
                            bordered
                            head-variant="light"
                            sticky-header="true"
                            no-border-collapse>
                            <template #cell(row_name_ru)="data">
                                <div class="text-left">{{ data.item.name_ru }}</div>
                            </template>
                            <template #cell(count)="data">
                                <b-form-input v-if="variantAttribute"
                                              class="text-right"
                                              :value="data.item.count"
                                              @change="v => data.item.count = v"
                                              @keyup.enter.exact="keyup13"
                                              @keypress="keyPress($event, '^[0-9]+$')"
                                              @blur="inputFixedVldtn(data.item, 'count', data.item.count, 0)">
                                </b-form-input>
                                <div v-else>{{ data.value }}</div>
                            </template>
                            <template #cell(price)="data">
                                <b-form-input v-if="variantAttribute"
                                              class="text-right"
                                              :value="data.item.price"
                                              @change="v => data.item.price = v"
                                              @keyup.enter.exact="keyup13"
                                              @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                              @blur="inputFixedVldtn(data.item, 'price', data.item.price, 2)">
                                </b-form-input>
                                <div v-else>{{ data.value }}</div>
                            </template>
                            <template #cell(rowTotal)="data">
                                <div class="text-right">{{ $n(data.item.rowTotal) }}</div>
                            </template>
                            <template #bottom-row="data">
                                <td class="text-left" colspan="3">Итого</td>
                                <td class="text-right">{{ $n(row.item.total) }}</td>
                            </template>
                        </b-table>
                    </b-card>
                </template>

            </b-table>
        </div>
        <files-updown :header="header"
                      :variant-attribute="variantAttribute"
                      :load="load"
                      @getFiles="getFiles"
                      @getNewFiles="getNewFiles($event)"
                      @delFile="delFile($event)"
                      ref="fileUpdown"
        ></files-updown>
        <modal-attach ref="modalAttach"
                      :row-files-input="row_files"
                      :header="header"
                      :is-add="isAdd"
                      :variant-attribute="variantAttribute"
                      @toggleIsAdd="toggleIsAdd($event)"
                      @fileUpload="fileUpload"/>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BudgetHeader from '@/modules/budget-request/budget-header-new.vue';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import BreadcrumbsFilter from '@/modules/budget-request/components/breadcrumbs-filter.vue';
import { Ax } from '@/utils';
import store from '../../../services/store';
import ModalAttach from '@/modules/budget-request/components/modal-attach.vue';
import FormsHandlerMixin1 from "../mixins/forms-handler-mixin-1";
import CBudgetFormsCopyData from '../components/budget-forms-copy-data.vue';
import FormsDownloadReprt from "../components/forms-download-reprt.vue";

export default {
    name: 'Form01-339',
    components: { BudgetHeader, FilesUpdown, BudgetFormsList, FormsDownloadReprt, BreadcrumbsFilter, ModalAttach, CBudgetFormsCopyData },
    mixins: [FormsHandlerMixin1],
    data() {
        return {
            form: {
                code: '01-339',
                name_kk: '',
                name_ru: 'Расчет затрат на прочие текущие трансферты другим уровням государственного управления',
                spf: {
                    name: '01-339-Текущие трансферты другим уровням государственного управления',
                    spf: '339'
                }
            },
            tableFields: [
                {
                    key: 'action',
                    label: ' '
                },
                {
                    key: 'number',
                    label: '№п/п'
                },
                {
                    key: 'name_ru',
                    label: 'Наименование на русском языке'
                },
                // {
                //     key: 'name_kk',
                //     label: 'Наименование на казахском языке'
                // },
                {
                    key: 'total',
                    label: 'Сумма (тыс. тенге)'
                },                {
                    key: 'files',
                    label: 'Файлы'
                },
                {
                    key: 'more',
                    label: ''
                }
            ],
            dataTypeFilter: null,
            variantAttribute: null,
            variantName: null,
            budgetForm: [],
            header: null,
            files: null,
            load: true,
            openDisabled: false,
            mrp: 0,
            obl: null,
            region: null,
            currentRegions: [],
            listExistingReg: [],
            isLoad: false,
            open: false,
            dictTransfers: [],
            row_files: [],
            isAdd: false,
            rowId: false,
            newRowStartId: -1,
        };
    },
    async mounted() {
        await this.getObl();
        // await this.loadRegions();
        await this.loadTransfers();
        await this.loadRegions();
    },
    methods: {
        openModalRowFilesByRowId(rowId, isAdd) {
            this.row_files = this.budgetForm.find(b => b.id === rowId)['row_files'];
            this.$refs.modalAttach.openModalRowAddFilesByRowId(rowId);
            this.rowId = rowId;
            this.isAdd = isAdd;
        },
        toggleIsAdd(return_object) {
            const curBudgetForm = this.budgetForm.find(b => b.id === this.rowId)
            curBudgetForm['row_files'] = return_object['row_files']
            curBudgetForm['files'] = return_object['num_attach_files']
            this.isAdd = return_object['isAdd'];
        },
        fileUpload(rowId) {
            this.$refs.fileUpdown.openModalFileUpload();
            this.$refs.fileUpdown.setRowId(rowId);
        },
        getNewFiles(files) {
            this.budgetForm.forEach(item => {
                let num_attach_files = 0;
                item['row_files'] = [...item['row_files'], ...files];
                item['row_files'].forEach((file) => {
                    if (file.row_id === item['id']) {
                        num_attach_files += 1;
                    }
                });
                item['files'] = num_attach_files;
            });
            this.$refs.modalAttach.addNewFiles(files);
        },
        delFile(fileId) {
            this.budgetForm.forEach(item => {
                item['row_files'].forEach((file, index) => {
                    if (file.file_id === fileId) {
                        item['row_files'].splice(index, 1);
                    }
                });
                item.files = item['row_files'].filter(i => i.row_id !== null).length;
            });
            this.$refs.modalAttach.delFile(fileId);
        },

        async getObl() {
            try {
                await fetch('/api-py/get-budget-obl/' + store.state._instanceCode)
                    .then(response => response.json())
                    .then(json => {
                        this.obl = json.obl;
                        this.region = json.region;
                    });
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса getObl()', error.toString());
            }
        },

        async loadRegions() {
            this.currentRegions = [];
            try {
                const response = await fetch('/api-py/get-regions-by-obl/' + this.obl);
                const items = await response.json();
                this.currentRegions = items;
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса регионов', error.toString());
            }
        }, // справочник регионов

        loadRegionsByTransfer(regionsData, dataItem) {
            const items = JSON.parse(JSON.stringify(this.currentRegions));
            const result = [];
            const currRegionsArr = regionsData ? regionsData[dataItem.id] : null;
            items.forEach(row => {
                const currRegion = currRegionsArr ? currRegionsArr.find(itm => itm.code === row.code) : null;
                const el = {};
                this.$set(el, 'id', currRegion ? currRegion.id : 0);
                this.$set(el, 'code', row.code);
                this.$set(el, 'name_ru', row.name_ru);
                this.$set(el, 'count', currRegion ? currRegion.count : 0);
                this.$set(el, 'price', currRegion ? currRegion.price : 0);
                this.$set(el, 'changed', false);
                Object.defineProperty(el, 'rowTotal', {
                    get: function () {
                        const sum = parseFloat(el.count) * parseFloat(el.price) / 1000;
                        return parseFloat((Math.round(sum * 1000) / 1000).toFixed(3));
                    }
                });
                const elP = new Proxy(el, {
                    set(target, prop, value) {
                        if (prop in target) {
                            if (prop === 'count' || prop === 'price') {
                                target.changed = true;
                            }
                            target[prop] = value;
                            return true;
                        } else {
                            throw new Erro(`No ${prop} field in target`)
                        }
                    }
                })
                result.push(elP);
            });
            result.sort((a, b) => a.name - b.name);
            return result;
        }, // справочник регионов

        async loadTransfers() {
            try {
                const filter = {
                    // region_id: this.obl // # если не передавать свойство region_id, то условия по региону не будет в запросе
                };
                this.dictTransfers = [];
                const response = await fetch('/api-py/dict/transfer/' + JSON.stringify(filter));
                const trList = await response.json();
                trList.sort((a, b) => a.code - b.code);
                trList.forEach(tr => {
                    this.dictTransfers.push({
                        code: tr.code,
                        name_ru: tr.name_ru
                        // name_kk: tr.name_kk
                    });
                });
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadTransfers()', error.toString());
            }
        },

        deleteItem(row, index) {
            this.$bvModal.msgBoxConfirm(
                'Очистить данные записи?',
                {
                    title: 'Подтверждение',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'ДА',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        // this.budgetForm.splice(index, 1);
                        if (row.id > 0) {
                            this.delete(row, index);
                        }
                    }
                })
                .catch(error => {
                    this.makeToast('danger', 'Ошибка удаления', error.toString());
                });
        }, // очистить значения строки

        resetData(item, index) {
            if (!item || !item.regionsList || item.regionsList.length === 0) return;
            item.regionsList.forEach((reg) => {
                if (reg.count !== 0) {
                    this.$set(reg, 'count', 0);
                }
                if (reg.price !== 0) {
                    this.$set(reg, 'price', 0);
                }
                if (reg.rowTotal !== 0) {
                    this.$set(reg, 'rowTotal', 0);
                }
                this.$set(reg, 'changed', false);
                this.$set(reg, 'id', 0);
            });
        }, // очистка данных во вложенных строках

        async delete(item, index) {
            this.$set(item, 'form', this.form.code);
            this.resetData(item, index);
            try {
                this.$set(this.header, 'value', this.calcTotalForDelete(this.budgetForm, 'total'));
                const response = await fetch('/api-py/delete-budget-request-form-with-row-attach-files/' + JSON.stringify(this.header), {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(item)
                });
                const result = await response.json();
                const response_region = await fetch('/api-py/delete-budget-request-form-01-339-regions/' + item.id, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    }
                });
                const result_region = await response_region.json();
                if ((response.status === 200) && (response_region.status === 200)) {
                    this.$set(item, 'id', index * -1);
                    this.$set(item, 'row_files', []);
                    this.makeToast('success', 'Сообщение', 'Запись удалена');
                    // await this.saveTotal();
                }
            } catch {
                this.makeToast('danger', 'Предупреждение', 'Ошибка удаления данных');
            }
        }, // удаление данныхA

        downloadRep() {
            Ax(
                {
                    url: '/api-py/budg_' + this.form.code.replace('-', '_') + '/' + JSON.stringify(this.header),
                    method: 'POST',
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Форма 01-339.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    this.makeToast('danger', 'Ошибка запроса downloadRep()', error.toString());
                }
            );
        },

        getFiles(data) {
            this.files = data;
        },

        keyPress: function (event, pattern) {
            // const regex = new RegExp('^[0-9]+$');
            // const regex = new RegExp('^-?\\d*\\d{0,9}$');
            // const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        openAll() {
            this.open = !this.open;
            for (const row of this.budgetForm) {
                row._showDetails = !this.open;
                this.showDetails(row);
            }
        },

        showDetails(row) {
            row._showDetails = !row._showDetails;
        },

        async loadDatas() {
            const that = this;
            that.load = true;
            

            let values = [];
            const existingIdList = []
            // fetching categories
            try {
                const response = await fetch('/api-py/get-budget-request-form-with-row-attach-files/' + JSON.stringify(that.header));
                if (response.status == 200) {
                    values = await response.json();
                    if (values.length) {
                        existingIdList.push(values[0].id)
                        values.sort((a, b) => {
                            existingIdList.push(a.id)
                            if (a.code < b.code) { return -1; }
                            if (a.code > b.code) { return 1; }
                            return 0;
                        });
                    } //else return;
                } else {
                    this.budgetForm.splice(0);
                    this.makeToast('danger', 'Ошибка запроса loadDatas()', `Error code: ${response.status}`);
                    return;
                }
            } catch (error) {
                this.budgetForm.splice(0);
                that.makeToast('danger', 'Ошибка запроса loadDatas()', error.toString());
                return;
            }

            const existingIdObj = await that.loadExistingRegions(existingIdList);

            const prepareFormData = []
            let index = 1;
            for (const item of that.dictTransfers) {
                const itemExist = values.find(val => val.code === item.code);
                const dataItem = {
                    id: itemExist ? itemExist.id : this.newRowStartId,
                    number: index,
                    code: item.code,
                    name_ru: item.name_ru,
                    changed: false,
                    // name_kk: item.name_kk,
                    rowFields: [
                        {
                            key: 'row_name_ru',
                            label: 'Наименование'
                        },
                        {
                            key: 'count',
                            label: 'Количество'
                        },
                        {
                            key: 'price',
                            label: 'Стоимость за единицу, тенге'
                        },
                        {
                            key: 'rowTotal',
                            label: 'Сумма, тыс.тенге (гр.2 х гр. 3)/1000'
                        }
                    ]
                };
                this.newRowStartId--;

                this.$set(dataItem, 'files', itemExist ? itemExist.files : 0);
                this.$set(dataItem, 'row_files', itemExist ? itemExist.row_files : []);

                this.$set(dataItem, 'regionsList', this.loadRegionsByTransfer(existingIdObj, dataItem));
                
                this.$set(dataItem, '_showDetails', false);
                Object.defineProperty(dataItem, 'total', {
                    get: function () {
                        let total = 0;
                        if (this.regionsList) {
                            this.regionsList.forEach(el => {
                                total += el.rowTotal;
                            });
                        }
                        return parseFloat((Math.round(total * 1000) / 1000).toFixed(3));
                    }
                });
                prepareFormData.push(dataItem);
                index++;
            }

            that.budgetForm = prepareFormData;            
            that.load = false;
        },

        async loadExistingRegions(existingIdList) {
            if (existingIdList.length === 0) return;
            let listExistingReg = [];
            try {
                const response = await fetch('/api-py/get-budget-request-form-01-339-regions/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(existingIdList)
                });
                listExistingReg = await response.json();
                listExistingReg.forEach(item => {
                    Object.defineProperty(item, 'rowTotal', {
                        get: function () {
                            const sum = parseFloat(item.count) * parseFloat(item.price) / 1000;
                            return parseFloat((Math.round(sum * 1000) / 1000).toFixed(3));
                        }
                    });
                });
                listExistingReg.sort((a, b) => {
                    if (a.name_ru < b.name_ru) { return -1; }
                    if (a.name_ru > b.name_ru) { return 1; }
                    return 0;
                });
                if (listExistingReg.length === 0) {
                    return;
                }
                const objExistingReg = {};
                listExistingReg.forEach(itm => {
                    if (objExistingReg[itm.parent_id]) {
                        objExistingReg[itm.parent_id] = [...objExistingReg[itm.parent_id], itm];
                    } else {
                        objExistingReg[itm.parent_id] = [itm];
                    };
                    
                })
                return objExistingReg;
                
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadExistingRegions()', error.toString());
            }
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        prepareForSave() {
            const values = [];
            for (const row of this.budgetForm) {
                const item = Object.assign({}, this.header);
                this.$set(item, 'id', row.id);
                this.$set(item, 'code', row.code);
                this.$set(item, 'name_ru', row.name_ru);
                this.$set(item, 'total', parseFloat(row.total));
                this.$set(item, 'row_files', row.row_files);

                this.$set(item, 'childRegionsList', row.regionsList);
                values.push(item);
            }
            if (values.length > 0) {
                this.save(values);
            } else {
                this.makeToast('warning', 'Сообщение', 'Данные не сохранены');
            }
        },

        async save(values) {
            this.isLoad = true;
            try {
                this.$set(this.header, 'value', this.total);
                const response = await fetch('/api-py/save-brform' + this.form.code + '/' +
                    JSON.stringify(this.header), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(values)
                });
                const result = await response.json();
                if (response.status === 200) {
                    this.makeToast('success', 'Сообщение', 'Данные сохранены');
                    if (!this.files || this.files.length === 0) {
                        this.makeToast('danger', 'Предупреждение', 'Внимание! Отсутствуют необходимые документы. Пожалуйста, прикрепите недостающие файлы.');
                    }
                    // await this.saveTotal();
                    await this.loadDatas();
                } else {
                    await this.loadDatas();
                    throw ' ';
                }
            } catch (e) {
                this.makeToast('danger', 'Предупреждение', e.toString());
            } finally {
                this.isLoad = false;
            }
        }, // сохранение данных

        async saveTotal() {
            this.$set(this.header, 'value', this.total);
            try {
                const response = await fetch('/api-py/save-budget-request-total/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(this.header)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    // this.makeToast('success', 'Сообщение', 'Данные сохранены');
                }
            } catch {
                this.makeToast('danger', 'Предупреждение', 'Ошибка сохранения итога');
            }
        } // сохранение итога
    },
    computed: {
        total() {
            let sum = 0;
            for (const row of this.budgetForm) {
                sum += parseFloat(row.total);
            }
            return Math.ceil(sum);
        }
    }
};
</script>
<style scoped>
    .filter-actions-flex {
        display: flex;
    }
</style>